import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './scss/index.scss';
import './scss/responsive.scss';
import './scss/fonts.scss';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
