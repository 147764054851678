import React from "react";

const About = () => {
    return (
        <section>
            <h2>About Me</h2>
            <p>
                My name is Diego Zamora and I am a web developer from México. I
                enjoy building and coding websites from small blogs to business
                sites. If you are a business looking a web presence or are
                looking to hire, conctact me.
            </p>
            <p>I design and build websites, and I love what I do.</p>
        </section>
    );
};

export default About;
