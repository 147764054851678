import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../img/logo.png";
import "../scss/nav.scss";

const Nav = () => {
    const Location = useLocation();

    const [pathname, setPathname] = useState("");

    useEffect(() => {
        setPathname(Location.pathname);
    }, [Location]);

    return (
        <nav>
            <img src={logo} alt="[...] logo" />
            {pathname !== "/" ? (
                <Link className="nav-btn" to="/" children="Home" />
            ) : (
                <Link className="nav-btn" to="/about" children="About" />
            )}
        </nav>
    );
};

export default Nav;
