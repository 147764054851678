import React, { Fragment } from "react";
import style from "../scss/routes.module.scss"

const Home = () => {
    return (
        <Fragment>
            <h1 className={style.home_title}>Diego Zamora</h1>
        </Fragment>
    );
};

export default Home;
